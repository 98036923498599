import React from "react";
import { Link } from "react-router-dom";
import './style.scss'; 
import Settings from "platform/services/settings";
import { SVGComponentShareTelegram } from "components/svg-components";

const TelegramShareButton = ({ productTitle }) => {
    const productUrl = window.location.href;
    const shareUrl = `https://t.me/share/url?url=${encodeURIComponent(productUrl)}&text=${encodeURIComponent(productTitle)}`;

    return (
        <Link
            to="#"
            className="telegram-share-button"
            onClick={(e) => { 
                e.preventDefault(); 
                window.open(shareUrl, "_blank"); 
            }}
        >
            <SVGComponentShareTelegram/>
            {Settings.translations.telegram_text}
        </Link>
    );
};

export default TelegramShareButton;