import Connection from '../../services/connection';
import { IResponse } from '../../constants/interfaces';
import { IBannerListResponseModel } from './models/response';

class BannerController {

  public static GetHeaderBanner = (key:number): Promise<IResponse<IBannerListResponseModel[]>> => {
    const result = Connection.GET({
      action: `list/${key}`,
      controller: 'Banner',
    });

    return result;
  };

  public static SendStatisticsGlobalPopUp = (promoId:number,isPositive:boolean): Promise<IResponse<IBannerListResponseModel[]>> => {
    const result = Connection.GET({
      action: `CollectInfo/${promoId}/${isPositive}`,
      controller: `PopupManage`,
    });

    return result;
  };
};

export default BannerController;