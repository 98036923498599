import { HubConnectionBuilder, HubConnection, LogLevel  } from '@microsoft/signalr';
import environment from './environment';
import Storage from './storage';
import DispatcherChannels from 'platform/constants/dispatcher-channels';




export function getValidNumberId(pathname:string) {
  const parts = pathname.split("/");
  const id = parts[parts.length - 1];

  return {
    isValidNumberId:(id !== "" && !isNaN(Number(id)) && Number.isInteger(Number(id)) ? Number(id) : null),
    id
  }
}

class Socket {
  private static chatConnection: HubConnection | null = null;
  private static popupConnection: HubConnection | null = null;
  private static cityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJiMzc0NDFmYi02YjZmLTQ2OTItODY1Zi1mYmE2ZDBjOGU5YmIiLCJ1bmlxdWVfbmFtZSI6IjM3NDk0NzE3MDIxIiwianRpIjoiYTNkOGNkMjktY2RiZC00MjY4LWIzNzEtNGI3NzlkMmQzMDEwIiwiaWF0IjoxNzM0MDg2MDY3LCJSZWNvdmVyeU9yZWdpc3RlciI6InRydWUiLCJuYmYiOjE3MzQwODYwNjYsImV4cCI6MTc2NTYyMjA2NiwiaXNzIjoid2ViQXBpIiwiYXVkIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAwLyJ9.9MVsqDNSsns29iC7J-6nGt4DezT80pnMUDCatagGg_U";


  public static connectChat(): void {
    if (!Socket.chatConnection) {
      Socket.chatConnection = new HubConnectionBuilder()
        .withUrl(`${environment.BASE_URL}chatHub`, {
          accessTokenFactory: () => window.localStorage.getItem("token") || '',
        })
        .withAutomaticReconnect([0, 2000, 10000, 30000])
        .configureLogging(LogLevel.Information)
        .build();

      Socket.chatConnection.serverTimeoutInMilliseconds = 60000;

      // ChatHub event listeners
      Socket.chatConnection.on('NewMessage', (data: any) => {
        const { isValidNumberId } = getValidNumberId(window.location.pathname);
        if (!isValidNumberId) {
          window.dispatchEvent(new CustomEvent(DispatcherChannels.SocketConnect, { detail: { data } }));
        }
      });

      Socket.chatConnection.on('NewOrderMessage', (data: any) => {
        const { isValidNumberId } = getValidNumberId(window.location.pathname);
        if (isValidNumberId) {
          window.dispatchEvent(new CustomEvent(DispatcherChannels.SocketConnect, { detail: { data } }));
        }
      });

      Socket.chatConnection.start()
        .then(() => console.log('ChatHub connected successfully.'))
        .catch((error) => console.error('Error starting ChatHub connection:', error));
    }
  }

  public static connectPopup(callBAck:any): void {
    if (!Socket.popupConnection) {
      Socket.popupConnection = new HubConnectionBuilder()
        .withUrl(`${environment.BASE_URL}popupHub`, {
          accessTokenFactory: () => window.localStorage.getItem("token") || '',
        })
        .withAutomaticReconnect([0, 2000, 10000, 30000])
        .configureLogging(LogLevel.Information)
        .build();

      Socket.popupConnection.serverTimeoutInMilliseconds = 60000;

      // Popup event listeners
      Socket.popupConnection.on('Popup', (data: any) => {
        window.dispatchEvent(new CustomEvent(DispatcherChannels.GlobalPopUp, { detail: { data } }));
      });

      Socket.popupConnection.start()
        .then(() => {
          callBAck()
          console.log('PopupHub connected successfully.')
        })
        .catch((error) => console.error('Error starting PopupHub connection:', error));
    }
  }

  public static disconnectChat(): void {
    if (Socket.chatConnection) {
      Socket.chatConnection.stop()
        .then(() => console.log('ChatHub disconnected successfully.'))
        .catch((error) => console.error('Error stopping ChatHub connection:', error));
      Socket.chatConnection = null;
    }
  }

  public static disconnectPopup(): void {
    if (Socket.popupConnection) {
      Socket.popupConnection.stop()
        .then(() => console.log('PopupHub disconnected successfully.'))
        .catch((error) => console.error('Error stopping PopupHub connection:', error));
      Socket.popupConnection = null;
    }
  }

  public static disconnectAll(): void {
    Socket.disconnectChat();
    Socket.disconnectPopup();
  }
}

export default Socket;






// import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
// import environment from "./environment";

// const cityToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJmMjEyMTlkNS0wNWIzLTQ0ZGMtOGNhYy0yYjcwMDY1NzQwYjkiLCJ1bmlxdWVfbmFtZSI6IiszNzQ5MTk1MzEzMSIsImp0aSI6Ijc3NjEwZmUyLTAwOWYtNDA0ZC1hZjU3LWVkMjk5YmEzNDViMiIsImlhdCI6MTczMzcyNzk1NCwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiVXNlciIsIm5iZiI6MTczMzcyNzk1NCwiZXhwIjoxNzQyMzY3OTU0LCJpc3MiOiJ3ZWJBcGkiLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjUwMDIvIn0.9Co70Iq8XMoPOgdvURES2lxMslaUCgrFD5Siq4ICAls"


// class Socket {
//   private static connection: HubConnection | null = null;

//   public static connectChat = async () => {
//     if (!Socket.connection) {
//       Socket.connection = new HubConnectionBuilder()
//         .withUrl(`${environment.BASE_URL_CITY}chat-events`, {
//           accessTokenFactory: () => cityToken || "",
//         })
//         .build();

//       Socket.connection.on("NewMessage", (message) => {
//         console.log("New message received:", message);
//         // Additional logic for handling new messages.
//       });

//       try {
//         await Socket.connection.start();
//         console.log("SignalR connected successfully.");
//       } catch (error) {
//         console.error("Error starting SignalR connection:", error);
//       }
//     }
//   };

//   public static disconnect = async () => {
//     if (Socket.connection) {
//       await Socket.connection.stop();
//       console.log("SignalR disconnected successfully.");
//       Socket.connection = null;
//     }
//   };
// }

// export default Socket;
