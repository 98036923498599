import Connection from '../../services/connection';
class ChatController {

  public static sendMessage = (formData: any): Promise<any> => {
  
    const result = Connection.POST({
      action: 'SendMessage',
      controller: 'Chat',
      body:formData,
      noneJSONBody: true,
    });

    return result;
  };

  public static sendOrderMessage = (formData: any): Promise<any> => {
  
    const result = Connection.POST({
      action: 'CreateMessageByOrderId',
      controller: 'Chat',
      body:formData,
      noneJSONBody: true,
    });

    return result;
  };

  public static fetchMessages = (body: any): any => {
    const result = Connection.POST({
      action: 'GetMessages',
      controller: 'Chat',
      body,
    });

    return result;
  };

  public static fetchMessagesOrder = (body: any): any => {
    const result = Connection.POST({
      action: 'GetMessagesByOrderId',
      controller: 'Chat',
      body,
    });

    return result;
  };
};

export default ChatController;