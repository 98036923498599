import ClickOutside from "components/click-outside";
import React, { useEffect, useState } from "react";
import "./style.scss"
import ROUTES from "platform/constants/routes";
import { SVGComponentCloseIcon } from "components/svg-components";
import BannerController from "platform/api/banner";
import environment from "platform/services/environment";
import Settings from "platform/services/settings";

enum PopupType {
    OnlyImgPopUp,
    ImgTextLandscape,
    ImgTextSquare
}

export default function GlobalPopUp({data,handleClose}) {
    const [switcher,setSwitcher] = useState<any>("")

    const negativeBtnHandler = () => {
        BannerController.SendStatisticsGlobalPopUp(data?.promoId,false).then(() => {
            handleClose()
        }).catch(error => {
            console.error('Error fetching basket count:', error);
        });
    };

    function extractProductsPath(url: string): string {
        return url.replace(/^\/[a-z]{2}\//, ''); // Removes "/hy/" or any language prefix
    }

    const positiveBtnHandler = () => {
        if (data?.navigationUrl) {
            const url = data.navigationUrl;
            const urlNew = extractProductsPath(url)
            const navigateTo = () => {
                const lang = Settings.language
                const newUrl = `${environment.SITE_URL}${lang}/${urlNew}`
                window.location.href = newUrl;
            };
            BannerController.SendStatisticsGlobalPopUp(data?.promoId,true).then(() => {
                navigateTo();
                handleClose();
            }).catch(error => {
                console.error('Error fetching basket count:', error);
            });
        }
    };


    if (!data) return null;
    return (
        <div className="globalPopUp">
            <ClickOutside className="innerPopUp" onClickOutside={negativeBtnHandler}>
                <div className="P-popup-first">
                    <div className="P-close-block G-cursor" onClick={negativeBtnHandler}>
                        <SVGComponentCloseIcon/>
                    </div>
                    <img src={data.backgroundImage} alt="" />
                    <div className="P-content G-flex G-flex-column G-align-center">
                        <h5 className={switcher === "dark" ? "G-dark-white" : ""}>{data.title}</h5>
                        {data.description && (
                            <p className={switcher === "dark" ? "G-dark-white" : ""}>{data.description}</p>
                        )}
                    </div>
                    <div className="P-next-block">
                        {data.negativeButtonText?.length > 0 && (
                            <div className="P-cancel-block">
                                <span
                                    className={`P-cancel G-cursor ${switcher === "dark" ? "G-dark-white" : ""}`}
                                    onClick={negativeBtnHandler}
                                >
                                {   data.negativeButtonText}
                                </span>
                            </div>
                        )}
                        <button
                            className="P-positive-button"
                            style={{ color: data.positiveButtonTextColor, backgroundColor: data.positiveButtonBackgroundColor }}
                            onClick={positiveBtnHandler}
                        >
                            {data.positiveButtonText}
                        </button>
                    </div>
                </div>
            </ClickOutside>
        </div>
    );
}
