import React, { useState } from "react";
import "./PopupPromo.scss";
import ROUTES from "platform/constants/routes";
import ClickOutside from "components/click-outside";
import { ProductSortEnum } from "platform/api/product/constants/enums";

const PopupHeader = ({ items, onClose }) => {

  const changeRoute = (id: number) => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=${ProductSortEnum.PriceHighToLow}&page=1`;
    window.location.href = newUrl;
  };

  
  return (
    <ClickOutside className="popup" onClickOutside={onClose}>
        <div className="popup-content">
            {items.map((item:any, index:number) => (
                <div className="popup-row" key={item.id} onClick={() => changeRoute(item?.id)}>
                  {item?.title}
                </div>
            ))}
        </div>
    </ClickOutside>

  );
};

export default PopupHeader;
