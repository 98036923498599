import { getCoordinates } from 'components/addressPopUp/utils';
import { SVGComponentAddressCenterIcon } from 'components/svg-components';
import React, { useState, useCallback } from 'react';
import { YMaps, Map, Placemark, SearchControl } from 'react-yandex-maps';

const YandexMapComponent = ({defaultCenter,callBack}:any) => {
    const [center, setCenter] = useState(defaultCenter);
    const [placemarkPosition, setPlacemarkPosition] = useState(center);
    const defaultZoom = 17;

    const handleBoundsChange = useCallback(async (event) => {
        const newCenterLatLng = event.get('newCenter');
        const coordinates = await getCoordinates(null,newCenterLatLng)

        callBack(coordinates)
        setCenter(newCenterLatLng);
        setPlacemarkPosition(newCenterLatLng); // Update placemark position
    }, []);

    return (
        <YMaps>
            <Map
                className='mapsYandex'
                defaultState={{ center, zoom: defaultZoom }}
                onBoundsChange={handleBoundsChange}
                style={{ height: '400px', width: '100%' }}
            >
                <Placemark
                    geometry={placemarkPosition}
                    properties={{ balloonContent: 'Center Marker' }}
                />
                <SVGComponentAddressCenterIcon/>
            </Map>
        </YMaps>
    );
};

export default YandexMapComponent;
