import * as React from 'react';
import Radio from 'components/radio';
import {PaymentTypeEnum} from 'platform/constants/enums';
import Storage from 'platform/services/storage';

import {PaymentTypeEnumItems} from '../../constants';
import IPay from './components/i-pay';
import {IOrderResultResponseModel} from 'platform/api/order/models/response';
import {IOrderCreateRequestModel} from "../../../../../platform/api/order/models/request";
import OrderController from 'platform/api/order';

export interface IPaymentMethod {
    resultInfo?: IOrderResultResponseModel;
    form?: IOrderCreateRequestModel;
    setCardType: (e: number) => void;
}

enum deliveryType {
    Delivery = 1,
    TakeAway = 2
}

const PaymentMethod = React.memo(({setCardType, form}: IPaymentMethod) => {
    const [type, setType] = React.useState(PaymentTypeEnum.Cash);

    // const [paymentMethods, setPaymentMethods] = React.useState();
    // React.useEffect(() => {
    //     (async () => { 
    //         const result = await OrderController.GetPeymentType();
    //      })();
    //      setPaymentMethods({ data: result.data });
    // }, []);

    const chooseType = (item: PaymentTypeEnum) => {
        setType(item);
        const query = new URLSearchParams(window.location.search);
        if (Storage.profile) {
            setCardType(item);
        }
        query.set('paymentType', item.toString());
        window.routerHistory.push(`${window.location.pathname}?${query.toString()}`);
    }

    const child = (item: PaymentTypeEnum) => {
        const content = {
            [PaymentTypeEnum.Cash]: null,
            [PaymentTypeEnum.IPay]: <IPay/>,
        };
        return content[item];
    }

    return <div className="P-choose-payment-type-section">
        <div className="P-payment-types G-flex G-flex-justify-start">

            {PaymentTypeEnumItems().map((item, index) =>
            <div key={index} className={item.class}>
                <Radio<PaymentTypeEnum> callback={(x: PaymentTypeEnum) => chooseType(x)} value={item.type} isChecked={type === item.type}>
                    {item.image ? <img src={item.image} alt=""/> : null}
                    {item.name ? <span>{item.name}</span> : null}
                    {type === item.type && child(item.type)}
                </Radio>
            </div>)}



            {/* {
                form?.deliveryType as unknown as deliveryType === deliveryType.Delivery ? PaymentTypeEnumItems().map((item, index) =>
                <div key={index} className={item.class}>
                    <Radio<PaymentTypeEnum> callback={(x: PaymentTypeEnum) => chooseType(x)} value={item.type} isChecked={type === item.type}>
                        {item.image ? <img src={item.image} alt=""/> : null}
                        {item.name ? <span>{item.name}</span> : null}
                        {type === item.type && child(item.type)}
                    </Radio>
                </div>)
                :
                PaymentTypeEnumItems().filter(item => item.type !== PaymentTypeEnum.PosTerminal && item.type !== PaymentTypeEnum.Cash).map((item, index) =>
                    <div key={index} className={item.class}>
                        <Radio<PaymentTypeEnum> 
                            callback={(x: PaymentTypeEnum) => chooseType(x)} 
                            value={item.type}
                            isChecked={type === item.type}
                        >
                            {item.image ? <img src={item.image} alt=""/> : null}
                            {item.name ? <span>{item.name}</span> : null}
                            {type === item.type && child(item.type)}
                        </Radio>
                    </div>
                )
            } */}
        </div>
    </div>
});

export default PaymentMethod;