// import * as React from 'react';
import React from 'react';
// @ts-ignore
import {createBrowserHistory} from 'history';
import {Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import Header from './components/header';
import RouteService from './platform/services/routes';
import ROUTES from './platform/constants/routes';
import Storage from './platform/services/storage';
import Settings from './platform/services/settings';
import Footer from './components/footer';
import FooterApp from './components/footer-app';
import ConfirmModal, {IProps as IConfirmModalProps} from './components/confirm-modal';
import Socket from './platform/services/socket';
import HelperComponent from './platform/classes/helper-component';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import './modules';
import 'moment/locale/hy-am';
import 'moment/locale/ru';
import 'moment/locale/en-gb';
import './assets/styles/index.scss';
import PaymentController from './platform/api/payment';
import Maps from "./components/maps";
import SuccessModal from "./components/success-modal";
import Environment from "./platform/services/environment";
import BasketController from 'platform/api/basket';
import moment from 'moment';
import ChatComponent from 'components/messageChat';
import Notifications from 'components/header/components/notifications';
import NotificationController from 'platform/api/notification';
import ClickOutside from 'components/click-outside';
import GlobalPopUp from 'components/globalPopUp';
import eventEmitter from 'modules/shared/Event/emmiterSocket';


interface IState {
    confirmOpen: boolean;
    confirmProps: IConfirmModalProps;
    initialLoading: boolean;
    generalAPILoaded: boolean;
    successPayment: boolean;
    bannerModal:boolean;
    spinModal:boolean;
    basketCount:number;
    isIncluded:boolean;
    currentPath:string;
    isSHowSuccess:boolean;
    notificationOpen:boolean;
    notificationIconNumber:number
    globalPopUpData:any
};

class App extends HelperComponent<{}, IState> {
    public state: IState = {
        currentPath:"",
        isIncluded:false,
        basketCount:0,
        generalAPILoaded: false,
        initialLoading: false,
        confirmOpen: false,
        successPayment: false,
        bannerModal:false,
        spinModal: false,
        confirmProps: {},
        isSHowSuccess:false,
        notificationOpen:false,
        notificationIconNumber: 0,
        globalPopUpData:undefined
    };
    private loadingTimeout: number;

    public componentWillUnmount() {
        super.componentWillUnmount();
        this.loadingTimeout && clearTimeout(this.loadingTimeout);
    }


    public async componentDidMount() {
        const root = document.documentElement;

        // this is for lang font 
        this.checkIfPaymentFailOrSuccess()


        let lang = localStorage.getItem("language")
        const pathNameParts = window?.location?.pathname?.split('/');
        const pathNameLang = pathNameParts[1];
        
        if (lang !== pathNameLang) {
            // Update language in localStorage
            window?.localStorage.setItem('language', pathNameLang);
            lang = localStorage.getItem("language");
        
            // Rebuild the URL with the new language and additional slugs/paths
            const remainingPath = pathNameParts.slice(2).join('/'); 
            window.location.href = `${Environment.SITE_URL}${pathNameLang}/${remainingPath}`;
        }
        if(!pathNameLang) {
            window.location.href = Environment.SITE_URL + Settings.language
        }
        
        const fontFamily = (lang === "hy") ? "Mardoto-Light" : "Roboto-Regular"
        root.style.setProperty('--custom-font-family', fontFamily);


        const alertify = await import('alertifyjs');
        moment.locale(Settings.shortCode);
        alertify.set('notifier', 'position',"bottom-left");
        alertify.set('notifier', 'delay', 5);

        window.abortableRequests = [];
        window.routerHistory = createBrowserHistory();
        const activeElement = document.activeElement;
        if (activeElement && activeElement instanceof HTMLInputElement) {
          activeElement.blur();
        }

        window.routerHistory.listen((location:any) => {
            const searchParams = new URLSearchParams(location.search);
            const paymentType = searchParams.get("paymentType");
        
            if (!paymentType) {
                window.scrollTo(0, 0);
            }
        });


        window.addEventListener(DispatcherChannels.GlobalPopUp, this.sendGlobalPopUpData);
        window.addEventListener(DispatcherChannels.ToggleConfirm, this.toggleConfirm);
        window.addEventListener(DispatcherChannels.GetBasketCount, this.getBasketCount);
        window.addEventListener(DispatcherChannels.CartItemsUpdate, this.getBasketCount);
        window.addEventListener(DispatcherChannels.checkRoute, (event: any) => {
            this.checkIfURLIncluded(event?.detail?.route);
        });
        window.addEventListener('scroll', this.toggleScroll);
        window.addEventListener(DispatcherChannels.logOut, Storage.fetchDefault);
        this.configureNotifications();
        this.safeSetState({ generalAPILoaded: true });
        try { 
            Socket.connectChat();
            Socket.connectPopup(() => {
                eventEmitter.emit('popupConnected');  
            });
        } catch(err) { 
            console.log(err,"chat error")
        }

        this.getBasketCount()
        const successCategory = await Storage.fetchDefault();
        

        if (successCategory) this.safeSetState({ initialLoading: true })
        else { window.location.reload() };

        const query = new URLSearchParams(window.location.search);
        const referralCode = query.get('referral');
        if (referralCode) {
            Settings.referralCode = referralCode;
        }
    }

    private getBasketCount = async () => {
        if (!Settings.guest) {
            BasketController.GetCount().then(result => {
              this.safeSetState({ basketCount: result.data });
            }).catch(error => {
              console.error('Error fetching basket count:', error);
            });
        }
    };

    private toggleConfirm = (e: CustomEvent) => {
        const {confirmOpen} = this.state;
        this.safeSetState({confirmOpen: !confirmOpen, confirmProps: e.detail || {}});
    };

    private sendGlobalPopUpData = (e: CustomEvent) => {
        this.safeSetState({globalPopUpData:e?.detail?.data || undefined});
    };

    private closeGlobalPopUp = () => {
        this.safeSetState({globalPopUpData:undefined});
    };

    private toggleScroll = (e: CustomEvent) => {
        const {notificationOpen} = this.state;
        if (notificationOpen) {
            this.safeSetState({notificationOpen: !notificationOpen});
        }
    };

    private oncloseFailPopUP = () => {
        this.setState({ isSHowSuccess:false });
    };

    private checkIfURLIncluded = (urlToCheck:string) => {
        const currentURL = window.location.href;
        const isIncluded = !!urlToCheck && currentURL.includes(urlToCheck)

        this.setState({ isIncluded })
    };

    private checkIfPaymentFailOrSuccess = () => {
        const currentURL = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
    
        const isIncludePay = 
            currentURL.includes("FailPayment") || 
            urlParams.get('success') === 'false';
    
        this.setState({ isSHowSuccess:isIncludePay });
    };

    private toggleNotifications = (e?: Event | React.SyntheticEvent) => {
        e && e.stopPropagation();
        const {notificationOpen} = this.state;
        this.safeSetState({notificationOpen: !notificationOpen});
    };

    public async configureNotifications() {
        const result = await NotificationController.GetUnseenList();
        if (result && result.success) {
            this.safeSetState({notificationIconNumber: result.data});
        }
    }

    public map = <Maps/>

    public render() {
        const {globalPopUpData,generalAPILoaded, initialLoading, confirmOpen, confirmProps, basketCount, isIncluded, isSHowSuccess, notificationOpen, notificationIconNumber} = this.state;
        if (typeof window === 'undefined' && initialLoading) { return null }
        
        return generalAPILoaded ? (     
            <Router history={window?.routerHistory}>
                {isSHowSuccess ? <SuccessModal bool2={false} bool1={false} onClose={this.oncloseFailPopUP}>
                    <>
                        <h3>{Settings.translations.order_fail}</h3>
                        <Link
                            className="G-main-button G-normal-link G-mt-30"
                            to={ROUTES.PROFILE.ORDERS.MAIN}
                            onClick={() => this.setState({isSHowSuccess:false})}
                        >
                            {Settings.translations.order_history}
                        </Link>
                    </>
                </SuccessModal> : null}

                <Header 
                    initialLoading={initialLoading} 
                    basketCount={basketCount} 
                    notificationIconNumber={notificationIconNumber} 
                    toggleNotifications={this.toggleNotifications}
                />

                <section className="G-page-content">
                    <Switch>
                        {RouteService.subscribeUnauthorized(routes => routes.map(item => <Route
                            exact={true}
                            key={item.path}
                            path={item.path}
                            render={(props) => (
                                <item.component initialLoading={initialLoading} {...props} />
                            )}
                        />))}

                        {!!Settings.token && !Settings.guest && RouteService.subscribeAuthorized(routes => routes.map(item => <Route
                            exact={true}
                            key={item.path}
                            path={item.path}
                            component={item.component}
                        />))}
                        <Redirect to={ROUTES.MN}/>
                    </Switch>

                    {notificationOpen &&
                    <Notifications 
                        onClose={this.toggleNotifications}
                    />
                }
                </section>
                {confirmOpen && <ConfirmModal 
                        title={Settings.translations.removeAddressP}
                        text={Settings.translations.removeAddressPUnderLine}
                        {...confirmProps} 
                    />
                }

                {(Storage.profile || !Settings.guest) && <ChatComponent isIncluded={isIncluded}/>}
                        
                <FooterApp basketCount={basketCount} notificationIconNumber={notificationIconNumber} toggleNotifications={this.toggleNotifications}/>
                <Footer isIncluded={isIncluded}/>


                <GlobalPopUp data={globalPopUpData} handleClose={this.closeGlobalPopUp}/>
            </Router>
        ) : null;
    }
}
export default App;