import React, { useState, useEffect, useRef, ReactNode } from "react";
import "./style.scss"

import { FaLocationPinLock } from "react-icons/fa6";
import Settings from "platform/services/settings";



interface PopupProps {
  onClose: () => void;
};

export default function PopupAddressNotFound({ onClose }: PopupProps) {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="popup-overlay">
      <div className="popup-content" ref={popupRef}>
        <FaLocationPinLock size={50} color="#196055"/>

        <p className="title">{Settings.translations.addressClose}</p>
        <button onClick={onClose} className="close-btn">Close</button>
      </div>
    </div>
  );
}