import * as React from 'react';
import { NavLink} from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import NotificationController from 'platform/api/notification';
import BasketController from 'platform/api/basket';
import {IProductSearcResponseModel} from 'platform/api/product/models/response';
import './style.scss';
import HelperComponent from 'platform/classes/helper-component';
import {Shared} from "../../modules";
import Storage from 'platform/services/storage';
import Broadcast from "../../platform/services/broadcast";
import Socket from "../../platform/services/socket";
import { SVGComponentMobileAplicationIcon, SVGComponentMobileAplicationIconGreen, SVGComponentMobileBasketIcon, SVGComponentMobileBasketIconGreen, SVGComponentMobileFavoriteIcon, SVGComponentMobileFavoriteIconGreen, SVGComponentMobileHomeIcon, SVGComponentMobileHomeIconGreen, SVGComponentMobileUserIcon, SVGComponentMobileUserIconGreen, SVGComponentNotification } from 'components/svg-components';
import { SetStyleForIosMainContent } from 'platform/hooks/setNotScrollledForIos';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';


interface IState {
    authOpen: boolean;
    isApps: boolean;
    categoryOpen: boolean;
    searchValue: string;
    mobileSearchOpen: boolean;
    searchOpen: boolean;
    searchLoader: boolean;
    searchResult: IProductSearcResponseModel | null;
    searchHistoryShown: boolean;
    mobileMenuOpen: boolean;
    scrollDown: boolean;
    appMobileMenuOpen: boolean;
};
declare global {
    interface Window {
        routerHistory: any;
    }
}


class FooterApp extends HelperComponent<any, IState> {

    public state: IState = {
        authOpen: false,
        categoryOpen: false,
        isApps: false,
        searchValue: '',
        mobileSearchOpen: false,
        searchLoader: false,
        searchOpen: false,
        searchResult: null,
        searchHistoryShown: false,
        mobileMenuOpen: false,
        scrollDown: false,
        appMobileMenuOpen: false,
    };

    private timer: any;

    public componentDidMount() {
        Broadcast.subscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
    }

    public componentWillUnmount() {
        super.componentWillUnmount();
        Broadcast.unsubscribe(DispatcherChannels.StorageUpdate, this.storageUpdate);
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    private storageUpdate = () => {
        this.forceUpdate();
    };

    private toggleAuth = () => {
        const {authOpen} = this.state;
        this.safeSetState({
            authOpen: !authOpen
        });
    };

    public render() {
        const { authOpen } = this.state;
        const { basketCount, notificationIconNumber } = this.props;

        return (
            <section className={'section-footer-app'}>


                <NavLink aria-label="Home" className={'P-link'} exact={true} to={ROUTES.MN} onClick={() => this.safeSetState({appMobileMenuOpen: false})}>
                    <SVGComponentMobileHomeIcon />
                    <SVGComponentMobileHomeIconGreen/>
                </NavLink>

                <NavLink aria-label="Category" className={'P-link'} to={ROUTES.CATEGORY.MAIN} onClick={() => this.safeSetState({appMobileMenuOpen: false})}>
                    <SVGComponentMobileAplicationIcon/>
                    <SVGComponentMobileAplicationIconGreen/>
                </NavLink>

                <NavLink aria-label="Cart" className={'P-link'} exact={true} to={ROUTES.CART} onClick={(e) => {
                    if (!Storage.profile) { 
                        e.preventDefault(); 
                        this.toggleAuth()
                    }
                    this.safeSetState({appMobileMenuOpen: false})
                }}>
                    <div className='link_basket'>
                        {!!basketCount && <span className={'count_basket'}>{basketCount > 99 ? '99+' : basketCount}</span>}
                        <SVGComponentMobileBasketIcon/>
                        <SVGComponentMobileBasketIconGreen/>
                    </div>
                </NavLink>

                <NavLink aria-label="Favorite" className={'P-link'} exact={true} to={ROUTES.PROFILE.FAVORITES.MAIN} onClick={(e) => {
                    if (!Storage.profile) { 
                        e.preventDefault(); 
                        this.toggleAuth()
                    }
                    this.safeSetState({appMobileMenuOpen: false})
                }}>
                    <SVGComponentMobileFavoriteIcon/>
                    <SVGComponentMobileFavoriteIconGreen/>
                </NavLink> 

                {!Settings.guest && Settings.token && <button onClick={this.props.toggleNotifications}
                    className="P-link-icon notification_title"
                >
                    <SVGComponentNotification/>
                    {!!notificationIconNumber && <span className='count_notification'>{notificationIconNumber > 99 ? '99+' : notificationIconNumber}</span>}
                </button>} 

                <NavLink aria-label="Profile" className={'P-link'} exact={true} to={ROUTES.PROFILE.MOBILE} onClick={(e) => {
                    if (!Storage.profile) { 
                        e.preventDefault(); 
                        this.toggleAuth()
                    }
                    this.safeSetState({appMobileMenuOpen: false})
                }}>
                    {(Storage.profile || !Settings.guest) && Storage?.profile?.photoPath ? 
                        <p className="P-image"><img alt="Profile" src={ResizerImgSizeDinamic(Storage.profile.photoPath,[100,100])}/></p>
                         : (<>
                        <SVGComponentMobileUserIcon/>
                        <SVGComponentMobileUserIconGreen/>
                    </>)}
                </NavLink>
                {authOpen && <Shared.Auth onClose={this.toggleAuth}/>}                 
            </section>
        );
    }
}

export default FooterApp;
