import * as React from 'react';
import { Link } from 'react-router-dom';

import { IBasketListResponseModel } from '../../../../../platform/api/basket/models/response';
import Settings from '../../../../../platform/services/settings';

import './style.scss';
import ROUTES from '../../../../../platform/constants/routes';
import {formatPercenteages, formatPrice, getMediaPath, truncateText} from '../../../../../platform/services/helper';
import { getBasketItemPriceInfo } from '../../../../../platform/services/basket';
import CountInput from '../../../../../components/count-input';
import HelperComponent from '../../../../../platform/classes/helper-component';
import { SVGComponentRemoveIcon } from 'components/svg-components';
import NewCountInput from 'components/count-input/newCountInput';
import DispatcherChannels from 'platform/constants/dispatcher-channels';

interface IProps {
  data: IBasketListResponseModel[];
  onQuantityChange(index: number, value: number): void;
  onDeleteBasketItem?(item: IBasketListResponseModel): void;
};

class BasketList extends HelperComponent<IProps, {}> {
  private priceInfo = (item: IBasketListResponseModel) => getBasketItemPriceInfo(item);
  public state: any = {
    showAllAllowedID: [],
  }

  private InsertToAllowEdStockId = (isShowID: boolean) => {
    const newAllowedIDSet = new Set(this.state.showAllAllowedID);
    newAllowedIDSet.add(isShowID);
    const newAllowedIDArray = Array.from(newAllowedIDSet);
    this.setState({ showAllAllowedID: newAllowedIDArray });
  };

  private CancelShowAllowEdIdStockId = (isShowID: boolean) => {
    const newAllowedIDSet = new Set(this.state.showAllAllowedID);
    newAllowedIDSet.delete(isShowID);
    const newAllowedIDArray = Array.from(newAllowedIDSet);
    this.setState({ showAllAllowedID: newAllowedIDArray });
  };


  public render() {
    const { data, onQuantityChange, onDeleteBasketItem } = this.props;
    const { showAllAllowedID } = this.state;

    return (<>  
      {data?.map((item: IBasketListResponseModel, index: number) => {
        const isDiscount = item?.totalDiscountedPrice < item.totalPrice;

        const allowToStockQuantyty = (item?.productQuantity < item?.stockQuantity)
        const disableIncreaseCountIem = showAllAllowedID.find((val:any) => val === item.id)

        const showErrorStockText = (item?.productQuantity > item?.stockQuantity)



        return (
          <div className={(!item.productStockQuantity ? 'P-out-of-stock-product' : '') + ` P-list-item G-my-30`} key={item.productId}>
            <div className="P-product-det basket_container G-flex">
            {isDiscount && <div className='percent_discount_favicon'>{formatPercenteages(item.discountPercent)}</div>}

              <Link to={ROUTES.PRODUCTS.DETAILS.replace(':id', item.productId).replace(':slug', item.slug)} className="P-image">
                <div className="P-image-parent">
                  <div className="G-square-image-block"
                    style={{ background: `url('${getMediaPath(item.productPhoto)}') center/contain no-repeat` }}
                  />
                </div>
              </Link>
              
              <div className='main_desc'>
                <Link className={`desc_c`} to={ROUTES.PRODUCTS.DETAILS.replace(':id', item.productId).replace(':slug', item.slug)}>
                  <div className="G-text-bold P-prod-title media-title">{item.productTitle}</div>
                  <span className={`DisableText ${(item?.stockQuantity && !allowToStockQuantyty && disableIncreaseCountIem) || !!showErrorStockText ? 'showDisableText' : ''}`}>
                    {Settings.translations.disabledText} {item?.stockQuantity}
                   </span>
                </Link>

                <div className={'P-price-wrapper'}>
                  {Number(formatPrice(getBasketItemPriceInfo(item).bonus, false)) ? 
                  <div className="G-clr-main G-text-bold P-bonus-amount-cart-item">
                    {formatPrice(getBasketItemPriceInfo(item).bonus, false)} {Settings.translations.bonus}
                  </div> : ''}
                </div>

                {!!item?.stockQuantity &&
                <div className="count_basket P-main-info G-flex G-flex-column G-flex-justify-between G-ml-40 G-mr-30">
                  <NewCountInput
                    onlyPlusIfOne={showErrorStockText} 
                    value={item.productQuantity}
                    step={item.stepCount}
                    min={item.minCount}
                    onChange={(value:any,id:any) => {
                      this.CancelShowAllowEdIdStockId(id)
                      onQuantityChange(index, value)
                    }}
                    allowToStockQuantyty={item?.stockQuantity}
                    item={item}
                    CallShowAllowEdCount={this.InsertToAllowEdStockId}
                  />
                </div>}


                {item?.stockQuantity !== 0 ?
                <div className={'P-prices'}>
                  <div>{isDiscount ? <del>{formatPrice(item.totalPrice)}</del> : null}</div>
                  <h4 className={`G-fs-18 P-cart-item-price ${isDiscount ? 'isDiscounted' : ''}`}>{formatPrice(item.totalDiscountedPrice)}</h4>
                </div> : null}
                
                {item?.stockQuantity === 0 && 
                <div className={'StockQuantytyText'}>
                  {Settings.translations.out_of_stock}
                </div>}

                {onDeleteBasketItem && <p className='remove_basketItem' onClick={() => onDeleteBasketItem(item)}><SVGComponentRemoveIcon/></p>}
              </div>
            </div>
          </div>
        )
      })}
    </>);
  }
};

export default BasketList;
