import * as React from 'react';

import HelperPureComponent from 'platform/classes/helper-pure-component';
import { IPagingResponse } from 'platform/constants/interfaces';
import { INotificationListResponseModel } from 'platform/api/notification/models/response';
import NotificationController from 'platform/api/notification';
import { infinityScrollPageLimit } from 'platform/constants';
import { NotificationTypeEnum } from 'platform/constants/enums';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import ClickOutside from 'components/click-outside';
import NotificationImage1 from 'assets/images/sale.svg';
import NotificationImage2 from 'assets/images/not.svg';
import NotificationImage3 from 'assets/images/help.svg';
import Settings from 'platform/services/settings';
import NotificationAnswer from '../notification-answer';
import { scrolledToBottomOfElement } from 'platform/services/helper';
import EmptyStateNotification from 'components/empty-state/notification';
import './style.scss';
import moment from 'moment';
import { SVGComponentBackToIcon } from 'components/svg-components';

interface IState {
  data?: IPagingResponse<INotificationListResponseModel>;
  selectedItem?: INotificationListResponseModel;
  loading: boolean;
  isLoading: boolean;
  appWidth: boolean;
};

interface IProps {
  onClose(e?: Event | React.SyntheticEvent): void;
}

class Notifications extends HelperPureComponent<IProps, IState> {

  public state: IState = {
    loading: false,
    isLoading: true,
    appWidth: false,
  };
  private pageNumber = 1;

  public componentDidMount() { this.fetchData(); }

  private fetchData = async () => {
    const { data } = this.state;
    const body = {
      pageSize: infinityScrollPageLimit,
      pageNumber: this.pageNumber,
    };

    const result = await NotificationController.GetList(body);
    if (data) result.data.list = data.list.concat(result.data.list) 
    if (window.innerWidth < 901) {this.safeSetState({appWidth: true});}
    this.safeSetState({ data: result.data, loading: false, isLoading: false });
  }

  private scrolled = (e: React.SyntheticEvent) => {
    const { loading, data } = this.state;
    if (scrolledToBottomOfElement(e.currentTarget as HTMLElement) && !loading && data && this.pageNumber < data.pageCount) {
      this.pageNumber += 1;
      this.fetchData();
    }
  }

  public render() {
    const { onClose } = this.props;
    const { data, selectedItem, isLoading, appWidth } = this.state;
    
    return (
      <ClickOutside className="P-header-notifications" onClickOutside={!selectedItem ? onClose : () => { console.log('') }}>
        <div onScroll={this.scrolled} className='main_not'>
          <div className='not_header'>
            <div className='main_title'>
              <div className="backIcon" onClick={onClose}><SVGComponentBackToIcon/></div>
              {Settings.translations.notifications}
            </div>
          </div>
          <div className="P-list-box">
            {!isLoading ? data && data?.list?.length ? (data.list.map(item => <this.ListItem key={item.id} item={item} />)) : (<EmptyStateNotification/>) : null}
          </div>
        </div>
      </ClickOutside>
    );
  }

  private ListItem = ({ item }: { item: INotificationListResponseModel }) => {


    return (
      <div className={`item_notification`}>
          <div className='title_container'>
            <h5 className='title'>
              <span>{item.title}</span> 
              <span className={`${!item.seen ? 'P-unseen' : ''}`}/>
            </h5>
            <span className='date'>{moment.utc(item.date).local().format('DD MMM YYYY HH:mm')}</span>
          </div>

          <span className='description'>{item.description}</span>
          {item.imageUrl && <img className='noteImage' src={item.imageUrl} alt={item.title}/>}
      </div>
    );
  }
}

export default Notifications;
